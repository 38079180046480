import { useTranslation } from 'react-i18next';

const NoProperties = () => {
  const { t } = useTranslation('form-builder');
  return (
    <div>
      <p className="my-4">{t('no-properties.title')}</p>
      <p>{t('no-properties.description')}</p>
    </div>
  );
};

export default NoProperties;
