import { FC, useEffect } from 'react';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import { FormType } from '../../../../models/FormTypes';
import NoProperties from '../../../form-builder/NoProperties';

const SectionDividerEditor: FC<EditorProps> = (props) => {
  const { action, patchAction, form } = props;

  useEffect(() => {
    if (!action.noninteractive) {
      patchAction({
        noninteractive: true,
      });
    }
  }, [action.noninteractive, patchAction]);

  useEffect(() => {
    if (action.visible === undefined) {
      patchAction({ visible: 'true' });
    }
  }, [action.visible, patchAction]);

  if (form.type === FormType.Quiz) return <NoProperties />;
  return (
    <div>
      <ConditionsProperties {...props} />
      <PreviewProperties {...props} />
    </div>
  );
};

export default SectionDividerEditor;
